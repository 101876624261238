/* Small only */
@media only screen and (max-width: 640px) {
  h1.primary {
    font-size: rem-calc(20);
  }

  h2 {
    font-size: rem-calc(20);
  }

  h3 {
    font-size: rem-calc(14);
    margin-top: rem-calc(10);
  }

  #section-top {
    p {
      font-size: rem-calc(11) !important;
    }

    .button.home-button {
      padding: rem-calc(8) rem-calc(12);
      font-size: rem-calc(16);
    }
  }

  .tabs-tray {
    margin-bottom: 0.625rem;
  }

  #section-contact {
    font-size: rem-calc(16);
  }
  .event-agenda {
    .agenda-row {
      .agenda-info {
        font-size: rem-calc(14);
      }
    }
  }

  #signup-box {
    h2 {
      font-size: rem-calc(15);
    }
  }

  #login-box-menu,
  #login-box,
  #share-box-menu,
  #share-box,
  #login-box-menu.notloggedin,
  #login-box.notloggedin,
  #share-box-menu.notloggedin,
  #share-box.notloggedin {
    position: absolute;
    right: 0px;
  }
}

/* Medium and below */
@media only screen and (max-width: 1200px) {
  .section-wrapper:before {
    opacity: 0.2;
    background-size: cover; /* Resize the background image to cover the entire container */
    background-position: 65% 0;
  }
  .event-faculty {
    .faculty-info {
      font-size: rem-calc(14);
      padding-right: rem-calc(20);

      h1.faculty-name {
        font-size: rem-calc(18);
      }
    }
    .faculty-image {
      max-width: 250px;
      min-width: 130px;
    }
  }
}

/* Medium only */
@media only screen and (max-width: 1024px) and (min-width: 640px) {
  #login-box-menu,
  #login-box,
  #share-box-menu,
  #share-box,
  #login-box-menu.notloggedin,
  #login-box.notloggedin,
  #share-box-menu.notloggedin,
  #share-box.notloggedin {
    right: 8px;
  }
}
