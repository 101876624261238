#cm {
  background-color: rgb(151, 194, 49);
  a,
  button.cc-link {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  a:hover,
  button.cc-link:hover {
    border-color: transparent;
  }
}

button.show-cookie-settings {
  color: $color-font-blue3;
  cursor: pointer;
}

button.show-cookie-settings:hover {
  color: $color-font-blue4;
}
