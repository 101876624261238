#section-top {
  font-family: "Mulish", sans-serif;
  border-bottom: 1px solid $color-font-blue;
  min-height: 299px;
}

.section-wrapper {
  position: relative;
}

.section-wrapper:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url("../images/header-home.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}

.section-content {
  position: relative;
}

#section-top.section-latest {
  h1.primary {
    margin-bottom: rem-calc(0);
  }
}

#section-resources {
  margin-bottom: rem-calc(50);
}

.tabs-tray {
  margin-bottom: -1px;
}

.section-middle {
  margin-bottom: rem-calc(50);
  min-height: 300px;
}

.section-subheading {
  font-size: rem-calc(18);
  font-weight: bold;
  line-height: rem-calc(20);
}

.section-date {
  font-size: rem-calc(14);
  font-weight: 600;
}

.thumb-image {
  border: 1px solid $color-font-blue;
  width: rem-calc(250);
  margin-right: rem-calc(20);
  margin-bottom: rem-calc(20);
}

#section-contact {
  background-color: $color-primary;
  min-height: 150px;
  font-size: rem-calc(22);
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

#section-top.event-top {
  border-bottom: 1px solid $color-font-blue;
}

.event-objectives,
.event-full-video,
.event-faculty,
.event-agenda,
.event-slides {
  padding-bottom: rem-calc(50);

  h1.primary {
    margin-bottom: rem-calc(35);
    font-family: "Raleway", sans-serif;
  }
}

.event-registration {
  background-color: $color-primary;
  border-bottom: 1px solid #ffffff;
  min-height: 150px;
  font-size: rem-calc(22);
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);
  color: #ffffff;
  a {
    color: #ffffff;
  }

  h1.primary {
    color: #ffffff;
    padding-top: 0px;
    margin-bottom: rem-calc(20);
    font-family: "Raleway", sans-serif;
  }
}

#regResult {
  min-height: rem-calc(50);

  .reg-success {
    padding-top: rem-calc(10);
    font-size: rem-calc(24);
    color: $color-success;
  }

  .reg-warning {
    padding-top: rem-calc(10);
    font-size: rem-calc(24);
    color: $color-primary;
  }

  svg {
    margin-right: rem-calc(20);
  }
}

.event-objectives {
  border-bottom: 1px solid $color-font-blue;

  .objective {
    font-weight: 700;
    color: $color-font-grey;
    padding-bottom: 20px;
  }
}

.event-full-video {
  border-bottom: 1px solid $color-font-blue;

  h1.primary {
    margin-bottom: rem-calc(2);
  }

  .video-placeholder {
    margin-top: rem-calc(50);
    padding-bottom: rem-calc(30);
    cursor: pointer;
  }

  .video-placeholder.no-video {
    cursor: default;
  }
}

.event-faculty {
  background-image: url("../images/blue-background.jpg"); /* The image used */
  background-position: top center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 300px;
  padding-bottom: rem-calc(30);

  .faculty-item {
    margin-bottom: rem-calc(20);
  }
  .faculty-image {
    max-width: 180px;
    min-width: 140px;
    height: auto;
    padding-right: rem-calc(20);
  }

  .faculty-info {
    color: white !important;
    font-size: rem-calc(18);
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(50);

    h1.faculty-name {
      font-size: rem-calc(21);
      font-weight: 700;
      margin-bottom: rem-calc(5);
    }
  }
}

.event-agenda {
  border-bottom: 1px solid $color-font-blue;
  font-size: rem-calc(20);
  .agenda-row {
    padding: rem-calc(20);
    .agenda-info {
      font-size: rem-calc(20);
    }

    .video-button {
      margin-bottom: 0px;
    }

    .agenda-title,
    .agenda-time {
      font-weight: bold;
    }
  }

  .agenda-row.alternate {
    background-color: $color-stripe-color;
  }
}

.event-slides {
  font-size: rem-calc(20);
}
