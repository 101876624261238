h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

h1.primary {
  color: $color-primary;
  font-size: rem-calc(28);
  font-weight: 700;
  line-height: 1.1em;
  padding-top: rem-calc(50);
  margin-bottom: rem-calc(35);
  font-family: "Raleway", sans-serif;
}

h2 {
  color: $color-font-blue;
  font-size: rem-calc(30);
  font-weight: 400;
  line-height: 1.2em;
  margin: 3px 0;
}

h2.secondary {
  color: $color-secondary;
  font-weight: 700;
}

h3 {
  font-size: rem-calc(22);
  margin-top: rem-calc(10);
}

h4 {
  font-size: rem-calc(18);
  margin-top: rem-calc(10);
}

h1.green {
  color: $color-secondary;
}

h1.orange {
  color: $color-primary;
}
