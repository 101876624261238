.signup-signin-box {
  padding-bottom: rem-calc(50);
  font-family: "Raleway", sans-serif !important;

  h1 {
    margin-bottom: rem-calc(20);
  }

  h2 {
    font-size: rem-calc(18);
    margin-top: rem-calc(20);
    font-weight: bold;
  }

  .mandatory {
    color: $color-red;
    font-size: rem-calc(22);
    line-height: 1;
  }
}

.login-required {
  font-size: rem-calc(30);
  margin-top: rem-calc(100);
}
