$color-primary: #f7901e;
$color-secondary: #9fc93c;
$color-background-grey: #d4d5d6;
$color-font-blue: #2e4554;
$color-font-blue2: #337ab7;
$color-font-blue3: #1779ba;
$color-font-blue4: #1468a0;
$color-font-black: #231f20;
$color-font-grey: #414042;
$color-stripe-color: #e3e3e3;
$color-success: #449d44;
$color-red: #cc4b37;
