.button {
  font-family: "Raleway", sans-serif;
  background-color: $color-font-blue !important;
  color: #fff;
  font-weight: bold;
  min-width: 120px;
  padding: 0.55em 1em;
}

.button.home-button {
  font-size: rem-calc(21);
  font-weight: bold;
  line-height: rem-calc(30);
  padding: rem-calc(15) rem-calc(30);
}

.button.register-button,
.button.signin-button {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: rem-calc(30);
  padding: rem-calc(10) rem-calc(20);
}

.tab-link.button {
  padding: rem-calc(15);
  margin-bottom: 0px;
  margin-top: rem-calc(10);
}

.button.primary {
  color: #ffffff;
  background-color: $color-primary !important;
}

.button.cancel {
  color: $color-font-grey;
  background-color: $color-background-grey !important;
}

input:checked ~ .switch-paddle.terms-optin {
  background: $color_success;
}

.btn-scroll-top {
  cursor: pointer;
}
