.top-bar,
.top-bar ul {
  font-family: "Raleway", sans-serif;
  background-color: #fff;
  padding-left: 0px;
}
.top-bar ul.menu-left {
  li a {
    font-size: rem-calc(22);
    color: $color-font-blue;
    font-weight: bold;
    padding: 0px rem-calc(30) 0 0;
  }

  li a.active {
    color: $color-primary;
  }
}

.top-bar .menu-right {
  margin-top: 8px;
}

a.nav-menu-item {
  font-size: rem-calc(16);
  color: $color-font-black;
  font-weight: bold;
  // display: inline-block;
  margin-right: rem-calc(20);
  svg {
    margin-right: rem-calc(10);
  }
}

a.nav-menu-item.btnSignIn {
  margin-right: rem-calc(0);
}

a.btnProfile {
  font-weight: normal;
}

.nav-separator {
  margin-right: rem-calc(20);
  font-weight: 900;
}

a.signout-button {
  color: $color-primary;
}

.title-bar {
  height: rem-calc(50);
  background: linear-gradient(to bottom, #2b414f, #1a272f);

  .menu-icon.active::after {
    /* the block itself, the first line */
    background: $color-primary;
    /* middle and bottom */
    box-shadow: 0 7px 0 $color-primary, 0 14px 0 $color-primary;
    /* respect WebKit */
    -webkit-box-shadow: 0 7px 0 $color-primary, 0 14px 0 $color-primary;
  }
}

#responsive-menu {
  background: linear-gradient(to bottom, #2b414f, #1a272f);
  padding: 0px;
  ul {
    background: none;
    li {
      background: none;
      width: 100%;
      padding: rem-calc(12) 0 rem-calc(12) rem-calc(20);
      border-bottom: white 1px solid;
      a {
        color: white;
        font-size: rem-calc(16);
      }

      a:hover {
        color: $color-primary;
      }
    }
  }
}

.title-bar-right {
  position: relative;
  float: right;

  a.hb-menu-item {
    color: white;
    margin-right: rem-calc(10);
  }

  span.hb-separator {
    margin-right: rem-calc(10);
  }
}

#login-box-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: $color-background-grey;
  z-index: 100;
  width: 140px;
  height: 50px;
  padding-top: 6px;

  .nav-menu-item {
    color: $color-primary;
    .grid-x {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

#login-box {
  position: absolute;
  top: 50px;
  right: 15px;
  background-color: $color-background-grey;
  z-index: 101;
  padding: rem-calc(20);
  font-family: "Raleway", sans-serif;
  max-width: 700px;

  h1 {
    font-family: "Raleway", sans-serif;
    font-size: rem-calc(34);
    color: $color-font-blue;
    font-weight: 700;
  }

  .login-box-section p strong {
    font-size: 24px;
    font-weight: bold;
    color: $color-font-grey;
  }

  p {
    font-size: rem-calc(20);
  }
}

#share-box-menu {
  position: absolute;
  top: 10px;
  right: 285px;
  background-color: $color-background-grey;
  z-index: 100;
  width: 140px;
  height: 50px;
  padding-top: 6px;

  .btnShare {
    color: $color-primary;
    .grid-x {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

#share-box {
  position: absolute;
  top: 50px;
  right: 285px;
  background-color: $color-background-grey;
  z-index: 101;
  min-width: 240px;
  max-width: 340px;
  padding: rem-calc(20);
  font-family: "Raleway", sans-serif;

  a.share-link {
    font-size: rem-calc(18);
    color: $color-font-blue2;

    svg {
      margin-right: rem-calc(15);
    }

    .share-item {
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }
  }

  a.share-link:hover {
    color: $color-primary;
  }
}

#share-box-menu.notloggedin,
#share-box.notloggedin {
  right: 158px;
}
