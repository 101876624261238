@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "../../node_modules/foundation-sites/scss/util/_util";
@import "../../node_modules/foundation-sites/scss/foundation";
@import "_settings";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css";

@include foundation-everything();

@import "_vars";
@import "_01_topnav";
@import "_02_buttons";
@import "_03_text";
@import "_04_sections";
@import "_05_modal";
@import "_06_login";
@import "_07_forms";
@import "_08_callouts";
@import "_09_cookieconsent";
@import "_responsive";

.reveal {
  padding-top: rem-calc(40);
}

body {
  position: relative;
  font-size: rem-calc(14);
  color: $color-font-blue;
  font-family: "Raleway", sans-serif;
  line-height: 1.42857;
}

a {
  color: $color-font-blue3;
}

a:hover {
  color: $color-font-blue4;
}

.spacer {
  border-top: 2px solid #efefef;
  margin-top: rem-calc(20);
}

hr {
  max-width: none;
}

p {
  margin-bottom: rem-calc(10);
  line-height: 1.42857;
}

.cyto-logo,
.hfsa-logo {
  display: inline-block;
  width: auto;
  height: 63px;
  padding: 5px;
  margin: 0 5px;
}

footer {
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);
}

.scroll-top .scroll-top-container {
  text-align: right;
}

#cookieNotice a {
  color: #ffffff !important;
}

.scroll-top .scroll-top-container .btn-scroll-top {
  display: inline-block;
  font-size: 30px;
  padding: 0 5px;
  background: #2e4554;
  color: #fff;
  border-top: 1px solid #231f20;
  border-left: 1px solid #231f20;
}

.scroll-top {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.iframe-responsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

.resource-container {
  margin-bottom: rem-calc(20);
  .resource-thumbnail img {
    border: 1px solid $color-font-grey;
  }
}
